@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&family=Roboto:wght@300;400;500;700;900&display=swap');

* {
  box-sizing: border-box;
}

h1,h2,h3,h4,h5,h6 {
  font-family: 'Roboto Condensed', sans-serif;
  margin: 0;
}

p {
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  background: rgb(138,113,168);
  font-family: 'Roboto', Arial, Verdana, Tahoma, sans-serif;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

header {
  background-color: #250035;
  padding: 1em;
}

header .inner-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
}

header a {
  color: #fff;
  font-size: 1.25rem;
  text-decoration: none;
  transition: all 0.3s ease;
}

header a:hover {
  opacity: 0.7;
}

header .brand a {
  color: #d7aeff;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
  font-size: 1.5rem;
  text-transform: uppercase;
}

header .brand a span{
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-weight: 800;
}

header .nav-links {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
}

header .nav-links li {
  display: inline-block;
  margin-right: 2em;
}

header .nav-links li:last-child {
  margin-right: 0;
}

.browse-btn {
  padding: 1em;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.browse-btn button {
  display: block;
  background: transparent;
  padding: 8px 15px;
  margin-right: .5em;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1rem;
  font-weight: bold;
  border:  none;
  border-bottom: #f7eeff solid 3px;
  color: #fff;
  cursor: pointer;
}

form {
  width: 100%;
  margin: 0 auto 2rem auto;
  display: flex;
  justify-content: center;
}

form input {
  padding: .8em;
  background: transparent;
  border: none;
  border-bottom: #f7eeff solid 3px;
  outline: none;
  color: #f7eeff;
  caret-color: #fff;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
}

form input::placeholder {
  color: #250035;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 1.3rem;
  opacity: .4;
}

form button {
  padding: 0;
  background: transparent;
  border: none;
  border-bottom: #f7eeff solid 3px;
  color: #f7eeff;
  font-size: 1.2rem;
  outline: none;
}

.results-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.card-container {
  width: 48%;
  background-color:#eeeeee;
  padding: 1em;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.poster-container {
  width: 150px;
  height: 200px;
  background: #dbdada;
  border-radius: 5px;
  overflow: hidden;
  flex-shrink: 0; 
}

.poster-container img {
  width: 100%;
  height: 100%;
  display: block; /* remove extra space below image */
}

.title {
  font-size: 2rem;
  line-height: 2.2rem;
  margin-bottom: .2em;
}
.year {
  font-weight: 300;
  margin-bottom: .2em;
}

p {
  font-size: .9rem;
  line-height: 1.2rem;
  margin-bottom: .5em;
}

.button-container {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
}

.add {
  display: block;
  background-color: #cc6917;
  padding: 8px 15px;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 999px;
  border:  #5f2e00 2px solid;
  color: #fff;
  cursor: pointer;
}

.add:disabled {
  pointer-events: none;
  opacity: .5;
}

.genre-tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap:.5em;
  margin-bottom: .8em;
}

.genre {
  display: block;
  background-color: #250035;
  color: #fff;
  padding: 5px 15px;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-size: .8rem;
  font-weight: 400;
  border-radius: 999px;
}

@media (max-width:909px){
  .card-container {
    width: 100%;
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-self: flex-start;
  align-items: center;
  gap: 1em;
  margin-bottom: 1em;
}

h3 {
  color: #fff;
  font-size: 3rem;
  font-weight: 300;
  opacity: .5;
}

.pill {
  display: inline-block;
  background-color: orange;
  padding: 5px 15px;
  font-size: 1.125rem;
  font-weight: 600;
  border-radius: 999px;
  color: #fff;
}

.anime-grid {
  display: grid;
  grid-template-columns: repeat(5,1fr);
  gap: 1em;
}

.anime-card {
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  border: 0;
  display: block;
}

.anime-card img {
  width: 100%;
  height: 100%;
  display: block;
}

.anime-card .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  transition: all 0.3s ease;
}

.anime-card:hover .overlay {
  border: 3px solid orange;
}

.anime-card .inner-card-controls {
  display: inline;
  background-color: red;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  padding: 3px;
  opacity: 0;
  transition: all 0.3s ease;
}

.anime-card:hover .inner-card-controls {
  opacity: 1;
}

.ctrl-btn {
  color: #fefefe;
  background-color: transparent;
  border: none;
  transition: all 0.3s ease;
  font-size: 1.25rem;
  padding: 5px;
  margin: 0;
}

.ctrl-btn:hover {
  color: orange;
  cursor: pointer;
}
